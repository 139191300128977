import React from 'react'
import PropTypes from 'prop-types'

import { prop, equals } from 'ramda'

// React Grid
import {
  DataTypeProvider,
} from '@devexpress/dx-react-grid'

const GridCourierTrackingTypeProvider = ({ columns }) => (
  <DataTypeProvider
    formatterComponent={
      ({ row, value }) => {
        const courierId = prop('courier_id', row)
        const trackingUrl = prop('tracking_url', row)

        if (trackingUrl) {
          return <div dangerouslySetInnerHTML={{
            __html: `<a href="${trackingUrl}" target="_blank">${value}</a>`
          }} />
        } else {
          return value || null
        }
      }
    }
    for={columns}
  />
)

GridCourierTrackingTypeProvider.propTypes = {
  columns: PropTypes.array.isRequired,
}

export default GridCourierTrackingTypeProvider
