import {
  prop, propEq, pipe, map, sum, join, length, reject,
  sortWith, ascend, filter, range,
} from 'ramda'
import moment from 'moment'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { LOCATION_CHANGE } from 'connected-react-router'

const INITIAL_STATE = Immutable({
  uiLoadingGetOrdersReadyToShip: false,
  uiLoadingGetOrdersDatedDelivery: false,
  uiLoadingGetShipmentsActive: false,
  // Orders Ready To Ship
  readyToShip: [],
  // Orders Dated Delivery
  datedDelivery: [],
  datedDeliveryNextDay: [],
  datedDeliveryColumns: [
    { name: 'order_reference', title: 'Order No.' },
    { name: 'delivery_date', title: 'Delivery Date' },
    { name: 'customer_delivery_contact', title: 'Consignee' },
    // { name: 'customer_delivery_city', title: 'City' },
    { name: 'customer_delivery_postcode', title: 'Postcode' },
    { name: 'action', title: 'View', getCellValue: row => row.order_reference },
  ],
  datedDeliveryColumnExtensions: [
    { columnName: 'action', width: 70, align: 'right' },
  ],
  // Shipments Active
  shipmentsActive: [],
  shipmentsActiveColumns: [
    { name: 'shipment_reference', title: 'Reference' },
    { name: 'courier_reference', title: 'Courier Ref.' },
    { name: 'consignee', title: 'Consignee' },
    { name: 'created_at', title: 'Date Added' },
    { name: 'dispatched_at', title: 'Date Dispatched' },
    { name: 'courier', title: 'Courier' },
    { name: 'service_name', title: 'Service' },
    {
      name: 'parcels',
      title: 'Parcels',
      getCellValue: row => {
        const parcels = pipe(prop('shipment_items'), length)(row)
        const weight = pipe(prop('shipment_items'), map(prop('weight')), sum)(row)
        return `${parcels} (${weight}kg)`
      },
    },
    {
      name: 'tracking',
      title: 'Tracking',
      getCellValue: row => join('\n', row.tracking)
    },
    { name: 'action', title: 'View', getCellValue: row => row.id },
  ],
  shipmentsActiveColumnExtensions: [
    { columnName: 'action', width: 70, align: 'right' },
  ]
})

// GET Orders Ready To Ship
const getOrdersReadyToShipAttempt = state => {
  return state.merge({
    uiLoadingGetOrdersReadyToShip: true,
  })
}

const getOrdersReadyToShipSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGetOrdersReadyToShip: false,
    readyToShip: data,
  })
}

const getOrdersReadyToShipFailure = (state) => {
  return state.merge({
    uiLoadingGetOrdersReadyToShip: false,
  })
}

// GET Orders Dated Delivery
const getOrdersDatedDeliveryAttempt = state => {
  return state.merge({
    uiLoadingGetOrdersDatedDelivery: true,
  })
}

const getOrdersDatedDeliverySuccess = (state, { data }) => {
  const datedDelivery = sortWith([ascend(prop('delivery_date'))], data.orders)
  const nextDayOrders = filter(
    order => {
      // if day is Friday then next 3 days needed
      // if day is Saturday then next 2 days needed
      let validOrder = false
      let days = 1
      if (moment().day() === 5) {
        days = 3
      } else if (moment().day() === 6) {
        days = 2
      }

      range(1, days + 1).map(day => {
        const nextDay = moment().add(day, 'days').format('YYYY-MM-DD')
        const deliveryDay = moment(order.delivery_date).format('YYYY-MM-DD')

        if (moment(nextDay).isSame(deliveryDay)) {
          validOrder = true
        }
      })

      if (validOrder) {
        return order
      }
    },
    datedDelivery
  )

  return state.merge({
    uiLoadingGetOrdersDatedDelivery: false,
    datedDelivery: datedDelivery,
    datedDeliveryNextDay: nextDayOrders
  })
}

const getOrdersDatedDeliveryFailure = (state) => {
  return state.merge({
    uiLoadingGetOrdersDatedDelivery: false,
  })
}

const removeDatedDeliveryNextDay = (state, { orderReference }) => {
  const nextDayOrders = reject(propEq('order_reference', orderReference), state.datedDeliveryNextDay)

  return state.merge({
    datedDeliveryNextDay: nextDayOrders,
  })
}

const resetOrdersReadyToShip = (state) => {
  return state.merge({
    // NOTE Charts not very dynamic so have to reset
    readyToShip: INITIAL_STATE.readyToShip,
  })
}

// GET Shipments Active
const getShipmentsActiveAttempt = state => {
  return state.merge({
    uiLoadingGetShipmentsActive: true,
  })
}

const getShipmentsActiveSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGetShipmentsActive: false,
    shipmentsActive: data,
  })
}

const getShipmentsActiveFailure = (state) => {
  return state.merge({
    uiLoadingGetShipmentsActive: false,
  })
}

const resetDashboardState = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Orders Ready To Ship
  [Types.GET_ORDERS_READY_TO_SHIP_ATTEMPT]: getOrdersReadyToShipAttempt,
  [Types.GET_ORDERS_READY_TO_SHIP_SUCCESS]: getOrdersReadyToShipSuccess,
  [Types.GET_ORDERS_READY_TO_SHIP_FAILURE]: getOrdersReadyToShipFailure,
  [LOCATION_CHANGE]: resetOrdersReadyToShip,
  // GET Orders Dated Delivery
  [Types.GET_ORDERS_DATED_DELIVERY_ATTEMPT]: getOrdersDatedDeliveryAttempt,
  [Types.GET_ORDERS_DATED_DELIVERY_SUCCESS]: getOrdersDatedDeliverySuccess,
  [Types.GET_ORDERS_DATED_DELIVERY_FAILURE]: getOrdersDatedDeliveryFailure,
  [Types.ORDER_SHIPPED]: removeDatedDeliveryNextDay,
  // GET Shipments Active
  [Types.GET_SHIPMENTS_ACTIVE_ATTEMPT]: getShipmentsActiveAttempt,
  [Types.GET_SHIPMENTS_ACTIVE_SUCCESS]: getShipmentsActiveSuccess,
  [Types.GET_SHIPMENTS_ACTIVE_FAILURE]: getShipmentsActiveFailure,
  // Reset reducer
  [Types.LOGOUT]: resetDashboardState,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
